<template>
  <div class="modal-add-workflow-modal wf-scroll">
    <header class="modal-header">
      <h5 class="modal-title">Credit Note Summary</h5>
    </header>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'CnSummary',
  mounted() {
    if (this.$route.params.summaryData) {
      const decodUrl = JSON.parse(
        Buffer.from(this.$route.params.summaryData, 'base64').toString('ascii')
      );
      sessionStorage.setItem('token', decodUrl.token);
    }
    this.$store.dispatch('shared/setStandAloneSummary', true);
  }
};
</script>
<style>
.wf-scroll {
  background-color: white;
  overflow: auto;
  height: calc(100vh - 25px) !important;
  margin: 5px 10px 5px 10px;
}
</style>
